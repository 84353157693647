@import "variables"

body.js-stop-transition *
  transition: none !important

body
  font: 1em $font-stack
  font-weight: 400
  max-width: 40em
  margin: 0 auto
  text-align: center
  color: $primary-color

  h1
    font-weight: 300
    font-size: 3em
    margin: 2em 0

  a.link
    color: inherit
    text-decoration: none
    font-size: 1.4em

    .link-box
      padding: 1em 0
      border-bottom: 1px solid $primary-color

  a.text-link
    text-decoration: underline

    &:hover
      color: $highlight-color

  footer
    font-weight: 300
    font-size: 0.8em
    margin: 12em 0 6em 0

    a
      color: inherit
      text-decoration: none
