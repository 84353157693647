body.js-stop-transition * {
  transition: none !important;
}

body {
  text-align: center;
  color: #ae9ca6;
  max-width: 40em;
  margin: 0 auto;
  font: 400 1em Zen Maru Gothic, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body h1 {
  margin: 2em 0;
  font-size: 3em;
  font-weight: 300;
}

body a.link {
  color: inherit;
  font-size: 1.4em;
  text-decoration: none;
}

body a.link .link-box {
  border-bottom: 1px solid #ae9ca6;
  padding: 1em 0;
}

body a.text-link {
  text-decoration: underline;
}

body a.text-link:hover {
  color: #6abaff;
}

body footer {
  margin: 12em 0 6em;
  font-size: .8em;
  font-weight: 300;
}

body footer a {
  color: inherit;
  text-decoration: none;
}
/*# sourceMappingURL=index.66c10914.css.map */
